import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import analyse from "./ana.svg";
import celular from "./devices.svg";
import sis from "./sis.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../componets/Header';

class Inicio extends Component {
  componentDidMount(){
    document.title = "MaxPdv"
  }
  render() {
    return (
      <>
        <section className="hero-header primary-header slider-header" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="hero-header-content">
                  <h1>O sistema de gestão no tamanho certo para o seu negócio!</h1>
                  <p>
                    O sistema perfeito para auxiliar micro e pequenos empresários, MEIs e profissionais autônomos, no gerenciamento dos seus negócios. Controle de fluxo financeiro e estoque. Comissionamento para vendedores, vendas online através do catálogo.
                  </p>
                  <div className="input-form">
                    <div className="input-group-append">
                  
                      <Link to="/planos" className='btn-ini'>Começe agora</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="hero-header-image">
                  <img src={analyse} style={{ width: 643, height: 435 }} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about-us" className="work section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>Diversas funcionalidades para gerenciar seu negócio</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faDollar} /></span>
                  </div>
                  <h3>Controle de Vendas</h3>
                  <p>Controle suas vendas de uma forma simples e fácil, sem complicações!</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faBoxOpen} /></span>
                  </div>
                  <h3>Estoque</h3>
                  <p>Seu estoque sempre atualizado, contabilizando entradas e saídas de mercadorias.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faChartBar} /></span>
                  </div>
                  <h3>Relatórios</h3>
                  <p>Crie relatórios de contas a pagar e a receber, vendas, fiados, comissão de vendedores, etc.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faStore} /></span>
                  </div>
                  <h3>Catálogo online</h3>
                  <p>Com o catálogo você pode criar sua loja virtual e receber pedidos de qualquer lugar</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="services-area section" id="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12">
                <div className="ft-1 info-media right">
                  <img src={celular} alt="Desktop and Mobile App" />
                </div>
              </div>
              <div className="col-lg-5 col-12">
                <div className="info-text">
                  <h2 className="main-title">Sistema inovador para o seu comércio!</h2>
                  <p className="des">Um sistema completo para seu ponto de venda o possibilita a gestão de informações e resultados em tempo real! </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="info-text">
                  <h2 className="main-title">No celular e no computador</h2>
                  <p className="des">Nosso sistema PDV (Ponto de Venda) é multiplataforma, o que significa que ele pode ser executado e utilizado em diferentes sistemas operacionais e dispositivos, como Windows, macOS, Android e iOS, oferecendo flexibilidade e compatibilidade para os usuários em várias plataformas.</p>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="ft-2 info-media right">
                  <img src={sis} alt="Desktop and Mobile App" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Inicio;