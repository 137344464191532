import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./p4.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import img from '../../img/undraw_page_not_found_re_e9o6.svg'
class Page404 extends Component {
    componentDidMount(){
        document.title = "Página não encontrada!"
    }
  render() {
    return (
      <>
        <section id="about-us" className="work section">
            <div className='area-404'>
            <img src={img}/>
            <h4>Oops! página não encontra!</h4>
            <span>A página que você está procurando não existe. Pode ter sido removida ou excluída.</span>
            <button className='btn-1' onClick={()=>{
              this.props.navigate(-1)
            }}><FontAwesomeIcon icon={faArrowLeft} />Voltar</button>
            </div>
   
        </section>
      </>
    );
  }
}

export default Page404;