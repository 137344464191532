import React from "react"
import { Route, Routes, Router, useNavigate} from "react-router-dom"
import Inicio from "./paginas/Inicio";
import Header from "./componets/Header";
import Page404 from "./paginas/Page404";
import Funcoes from "./paginas/Funcoes";

function App() {
  const navigateTo = useNavigate();
  return (
    <>
    <Header/>
    <Routes>
    
    <Route exact  path="/"  element={<Inicio/>} />
    <Route exact  path="/funcoes"  element={<Funcoes/>} />
    <Route exact  path="*"  element={<Page404 navigate={navigateTo}/>} />
    </Routes>
    </>
  );
}

export default App;
